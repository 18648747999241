#sportspage-odds {
	position: relative;
	width: 100%;
	min-width: 800px;
	min-height: 100vh;
	background: black;
	color: white;
/*	overflow-y: scroll;*/
	padding-bottom: 5vh;
}
/*
.sportspage-odds-background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: black;
}*/

.sportspage-odds-page-header {
	position: relative;
	width: 100%;
	height: 150px;
	min-height: 120px;
	background: #222222;
}

.sportspage-odds-header-inner {
	display: flex;
	position: relative;
	top: 15px;
	width: 95%;
	height: 120px;
	max-width: 1200px;
	margin: auto;
	background: black;
	overflow-x: scroll;
	overflow-y: hidden;
}

.odds-header-logo {
	margin-right: 5px;
}

.odds-header-logo-book {
	display: flex;
	flex-direction: column;
	align-content: space-between;
	width: 12vw;	
	height: 120px;
	min-width: 180px;
/*	margin: 5% 0;*/
	background: white;
	color: black;
	font-size: 0.8em;
	/*background: blue;*/
}

.odds-header-logo-book > div:first-child {
	display: flex;
	height: 90px;
}

.odds-header-logo-book > div:last-child {
	height: 30px;
	background: #777777;
	line-height: 30px;
	color: white;
	font-size: 1.2em;
	font-family: "Alfa Slab One";
}

.odds-header-logo-book > div > div {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.odds-header-logo img {
	margin: auto 0;
	width: 6vw;
	min-width: 90px;
/*	height: 4vw;*/
	background: white;
}

.odds-header-logo-view-all {
	display: flex;
	position: relative;
	width: 8vw;
	min-width: 100px;
	height: 100%;
	text-align: center;
	background: #ff4c4c;
	background: #f33232;
	cursor: pointer;
	/*margin: 5% 0;*/
}

.odds-header-logo-view-all > div {
	display: inline-block;
	align-self: center;
	width: 100%;
	font-size: 0.8em;
}

.odds-compare-body {
/*	display: flex;
	flex-direction: column;*/
	position: relative;
	width: 100%;
	/*max-width: 1200px;*/
	margin: 0vh auto;
	margin-bottom: 5vh;
}

.titan-odds-logo {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
}

.titan-odds-logo img {
	height: 50px;
	padding: 0.7em 0.5em 0.5em 0.2em;
}

.odds-compare-body-header-wrapper {
	position: relative;
	height: 80px;
}

#odds-compare-body-header {
	display: flex;
	justify-content: center;
	position: relative;
	top: 0;
	box-shadow: 0 3px 3px #000000;
	margin-bottom: 1em;
	z-index: 1;
}

#odds-compare-header-background {
	display: flex;
	justify-content: space-between;
	/*position: absolute;*/
	/*width: calc(100%-1em);*/
	width: 100%;
	min-width: 800px;
	max-width: 1200px;
	top: 0;
	left: 0;
	background: #222222;
	/*height: calc(65px + 1em);*/
/*	background: #222222;*/
/*	-webkit-transition: background 0.2s ease-in-out;
    -moz-transition: background 0.2s ease-in-out;
    -ms-transition: background 0.2s ease-in-out;
    -o-transition: background 0.2s ease-in-out;*/
}

.sticky-header #odds-compare-body-header {
	position: sticky;
	position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    background: white;
    position: fixed;
    left: 0;
    right: 0;
}

.sticky-header #odds-compare-header-background {
	background: transparent;
	/*display: flex;
	justify-content: space-between;
	top: 0;
	left: 0;
	padding: 0.5em;*/
}

.sticky-header .odds-compare-body-header-book {
	position: relative;
	top: -1px;
	left: -1px;
	border: 1px solid black;
/*	width: calc(6vw + 2px);
	min-width: 88px;*/
	margin-right: 3px;
}

.odds-compare-nav {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: calc(25% - 0.5em + 5px);
	min-width: 200px;
	z-index: 1;
	padding: 0.5em 0 0 0.5em;
/*	background: blue;*/
	/*position: absolute;
	top: 0;
	left: 0;*/
}

.odds-compare-nav select {
	width: 70%;
	background: black;
	color: white;
	margin-bottom: 0.5em;
}

.odds-compare-right {
	position: absolute;
	top: 0;
	left: 25%;
	z-index: 1;
}

.odds-compare-body-header-books {
	display: flex;
	width: 75%;
	padding: 0.5em 0;
}

.odds-compare-body-header-book {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	width: 6vw;
	height: 4vw;
	min-width: 90px;
	min-height: 60px;
	margin-right: 5px;
	background: white;
	color: black;
}

.odds-compare-body-header-book img {
	align-self: center;
	position: relative;
	width: 5.5vw;
	min-width: 80px;
}
.odds-compare-body-header-book div {
	position: relative;
	width: 6vw;
	min-width: 90px;
	line-height: 4vw;
	font-family: Anton;
	/*font-family: Rubik;
	font-weight: 600;*/
	font-size: 2em;
}

.odds-compare-body-games {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	margin-top: 3vh;
	/*background: black;*/
}

.odds-compare-body-top-buffer {
	height: 70px;
}

.odds-compare-no-games {
	margin-top: 100px;
}

.odds-compare-body-games-section {
	margin-bottom: 1em;
}

.odds-compare-section-date {
	position: relative;
	margin-bottom: 0.2em;
	text-align: left;
	font-size: 1.5em;
	font-family: "Rubik";
}

.best-odds-explainer {
	display: flex;
	justify-content: flex-end;
	align-content: center;
	position: absolute;
	right: 0;
	bottom: 0.2em;
	width: 20%;
	height: 70%;
	font-size: 0.5em;
}

.best-odds-explainer > div {
/*	display: inline-block;
	text-align: center;*/
/*	vertical-align: middle;
	align-self: center;*/
	align-self: flex-end;
	margin-left: 5px;
/*	border-radius: 3px;*/
}

.best-odds-explainer > div:last-child {
	max-width: 60px;
	width: 40%;
	height: 100%;
	background: #22d6ff;
}

.odds-compare-body-game {
	display: flex;
	background: #222222;
	margin-bottom: 5px;
}

.odds-compare-body-game-details {
	display: flex;
	width: 25%;
	min-width: 200px;
	border-right: 5px solid black;
}

.odds-compare-body-game-time {
	width: 25%;
	/*padding: 1.1em 0 0 0.8em;*/
	padding: 0 1.5em 0 1em;
	text-align: left;
	font-size: 0.8em;
	line-height: 55px;
}

.odds-compare-game-teams {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	text-align: left;
/*	padding: 0.5em;*/
}

.odds-compare-game-teams > div {
	/*line-height: 55px;*/
	/*padding: 0.2em;*/
}

.odds-compare-game-odds {
/*	display: flex;
    flex-direction: column;*/
	position: relative;
	width: 75%;
	height: 110px;
	/*height: 100%;*/
}

.odds-compare-game-odds-side {
	width: 100%;
	height: 50%;
	background: #272727;
	/*border-bottom: 1px solid #111111;*/
}

.odds-compare-game-odds-side.second {
	border-bottom: none;
	background: #222222;
}

.odds-compare-game-odds-side {
	display: flex;
}

.odds-compare-none {
	display: flex;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;
}

.odds-compare-none > div {
	align-self: center;
}

.odds-compare-item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 6vw;
	min-width: 90px;
	margin-right: 5px;
	align-self: center;
}

.odds-compare-item > div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
	width: 55px;
	height: 55px;
	/*background: black;
	background: #333333;*/
	border-radius: 5px;
}

.odds-compare-item.best-odds > div {
	background: #6ae000;
	background: #22d6ff;
	color: black;
}

.odds-compare-item > div > div {
	align-self: center;
	position: relative;
	bottom: 0.1em;
}

.odds-compare-item > div > div:last-child {
	font-size: 0.7em;
	margin-top: 0.1em;
}

.odds-compare-item > div > div:first-child {
	font-size: 1.1em;
}

.odds-compare-item > div > div.odds-compare-total {
	margin-top: 0.2em;
	font-size: 0.9em;
}

/*.odds-compare-item > div > div.odds-compare-moneyline {
	font-size: 0.9em;
}*/

.odds-compare-item span {
	font-size: 0.8em;
}

.odds-compare-moneyline span {
	margin-left: 0.2em;
}


