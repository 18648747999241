
.compare-page .titan-odds-logo {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	padding: 0.2em 0 0.5em 0.2em;
	margin-left: 0.5em;
	cursor: pointer;
}

.compare-page .titan-odds-logo img {
	height: 50px;
}


.compare-page-body {
	margin-top: 10vh;
}