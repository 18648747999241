
._404 {
	height: 100vh;
	padding-top: 20vh;
	color: white;
}

._404-header {
	font-size: 100px;
	font-family: "Rubik";
}

._404-body {
	font-size: 20px;
}

._404-button {
	margin-top: 20px;
	background: transparent;
	width: 180px;
	height: 50px;
	color: #22d6ff;
	color: white;
	border: 1px solid #22d6ff;
	border-radius: 0;
	box-shadow: none;
	font-size: 18px;
	font-weight: 700;
}
/*
._404-button:hover {
	background: #22d6ff;
	color: white;
}*/