
.oddsboard {
	position: relative;
	width: 100%;
	min-width: 600px;
	background: black;
	height: 100vh;
	color: white;
	/*color: #6ae000;*/
	background: #000000;
	/*background: radial-gradient(circle, rgba(34,32,32,1) 0%, rgba(0,0,0,1) 100%);*/
	/*background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 100 100'%3E%3Crect x='0' y='0' width='60' height='60' fill-opacity='0.38' fill='%23666666'/%3E%3C/svg%3E");
	*/opacity: 1;
	/*background-image: url("");*/
}


.test {
	position: absolute;
	top: 0;
	color: white;
}

.oddsboard-background {
	position: relative;
	width: 100%;
	min-width: 600px;

	background-image: url("https://wallpaper.dog/large/10861237.png");
	background-image: url("../../images/led-background.png");
	background-size: cover;
	background-attachment: fixed;
	opacity: 0.3;
	height: 100vh;
}

#oddsboard-body {
	position: fixed;
	top: 0;
	width: 100%;
	min-width: 600px;
	height: 100vh;
	overflow-y: scroll;
}

#oddsboard-body, #oddsboard-data-body {
	opacity: 1;
	-webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
}



#oddsboard-body.hide, #oddsboard-data-body.hide {
	opacity: 0;
	-webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
}

.oddsboard-login {
	position: relative;
	width: 50%;
	margin: 0 auto;
	top: 10vh;
	
	padding-bottom: 10vh;
}

.oddsboard-login-header {
	font-size: 2em;
	font-family: "Alfa Slab One";
	margin-bottom: 5vh;
	/*font-family: "Permanent Marker";
	font-family: "Montserrat";*/
}

.oddsboard-login form {
	display: inline-block;
	position: relative;
	margin-top: 1vh;
	width: 100%;
	max-width: 400px;
}

.oddsboard-login label {
	float: left;
	font-size: 1em;
	text-align: left;
	margin-top: 0.5em;
}

.oddsboard-login-form-lower {
	display: flex;
	flex-direction: row;
}

.oddsboard-login .checkbox-holder {
    display: flex;
    flex-direction: row;
    width: 40%;
   /* align-content: right;
    justify-content: right;*/
    /*position: absolute;
    right: 0;*/
    justify-content: flex-end;
    vertical-align: middle;
    font-size: 0.8em;
    margin-top: 0.5em;
}

.oddsboard-login .checkbox-holder > label {
    margin: 0 0.3em 0 0;
}

.oddsboard-login .checkbox-holder > input {
    cursor: pointer;
}

.oddsboard-login label#login-error {
	width: 100%;
	text-align: center;
	color: #ffb300;
	font-size: 0.8em;
	margin-top: 0.5em;
	opacity: 0;
}

.oddsboard-login label#login-error.show {
	opacity: 1;
}

.oddsboard-password-holder > div:last-child {
	position: relative;
}

.oddsboard-password-holder > div:last-child {
	position: absolute;
    top: 1.2vh;
    right: 0;
    font-size: 0.8em;
    color: white;
    cursor: pointer;
}


.oddsboard-login input[type=text], .oddsboard-login input[type=password] {
	width: 100%;
	display: inline-block;
	border: 1px solid #ccc;
	padding: 10px;
	border-radius: 4px;
	box-sizing: border-box;
	margin-top: 0.2em;
}

.oddsboard-login textarea {
	width: 100%;
	height: 20vh;
	display: inline-block;
	border: 1px solid #ccc;
	padding: 10px;
	border-radius: 4px;
	box-sizing: border-box;
	margin-top: 0.2em;
	word-wrap: break-word;
    word-break: break-all;
    overflow: auto;
}

/* Style the submit button */
.oddsboard-login input[type=submit] {
	margin-top: 1.5vh;
	min-width: 100%;
	padding: 12px;
	border-radius: 5px;
	border: none;
	font-size: 16px;
	cursor: pointer;
	text-align: center;
	background-color: #ee1140;
	color: white;
	font-weight: bold;
	font-family: "Montserrat";
}

.oddsboard-login input[value=Logout] {
	padding: 8px;
	background-color: #777777;
}

/* Add a background color to the submit button on mouse-over */
.oddsboard-login input[type=submit]:hover {
  background-color: #ffb300;
  background-color: #ff194a;
}

.oddsboard-login-instructions {
	margin-top: 5vh;
	text-align: left;
}

.oddsboard-login-instructions li {
	margin-bottom: 2vh;
}

.oddsboard-login-instructions a {
	margin-left: 0.3em;
	color: #ffb300;
}

.oddsboard-login-instructions span {
	margin-left: 0.3em;
	font-style: italic;
}

.oddsboard-instructions-section {
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-bottom: 3vh;
	line-height: 1.2;
}

.oddsboard-data-reminder {
	margin-top: 5vh;
	font-size: 0.9em;
	line-height: 1.3em;

}

.oddsboard-data-reminder > div:first-child {
	color: #ffb300;
/*	color: #ee1140;*/
	margin-bottom: 0.5vh;
}

.oddsboard-instructions-num {
	font-size: 1.5em;
	font-family: "Alfa Slab One";
}

.oddsboard-instructions-section-body {
	margin-left: 1.5em;
}

.oddsboard-instructions-section-body > div:nth-child(1) {
	font-size: 1.5em;
	font-family: "Alfa Slab One";
	margin-bottom: 0.3em;
}

/* ------------------------------ Schedule ------------------------------ */

#oddsboard-schedule {
	position: relative;
	width: 70%;
	margin: 0 auto;
	padding-top: 5vh;
	font-size: 1.8em;
	height: 90vh;
	overflow-y: scroll;
}

#oddsboard-schedule > div {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: flex-start;
	position: relative;
	width: 100%;
	height: 90vh;
}

.odds-game-status {
	position: relative;
	width: 10vh;
	text-align: left;
	margin-right: 2vh;
	font-size: 0.7em;
	margin-top: 0.15em;
	color: white;
}

.odds-game-status > div {
	position: absolute;
	top: 0;
	left: 0;
}

.odds-teams {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	text-align: left;
	color: #6ae000;
}

.odds-teams > div {
	display: flex;
}

.odds-teams > div > div:nth-child(2) {
	margin-left: auto;
}

.odds-teams span {
	position: relative;
	/*bottom: 0.05em;*/
	margin-left: 0.3em;
	font-size: 0.8em;
}

.oddsboard-league {
	display: fixed;
	position: relative;
	text-align: left;
	top: 2vh;
	left: 5vh;
	font-size: 3em;
	font-weight: bold;
}

.oddsboard-game {
	display: flex;
	width: 45%;
	padding: 1vh 0;
	border-bottom: 1px solid rgba(255,255,255,1);
	font-size: 1.1em;
/*	border-bottom: 1px solid #6ae000;*/
}

.flash-enter {
  opacity: 0;
}
.flash-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out 500ms;
}
.flash-exit {
  opacity: 1;
}
.flash-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.odds-game-right {
	position: relative;
	margin-left: auto;
	text-align: right;
	color: #6ae000;
	height: 2.2em;
}

.odds-game-right > div {
	position: absolute;
}

.oddsgame-team {
	position: relative;
}

.oddsgame-team span {
	font-size: 0.6em;
}

.odds-game-score {
	position: absolute;
	right: 0;
	color: white;
	font-weight: bold;
}

/*.odds-game-right > div > div {
	position: relative;
}

.odds-game-right > div > div div {
	position: absolute;
	top: 0;
	right: 0;
	height: 1.2em;
}*/

/*.odds-game-right > div > div:first-child {
	position: absolute;
	right: 0;
	top: 0;
}*/

.odds-game-right > div > div:last-child div {
	bottom: 0;
}

.oddsboard-chart-holder {
	display: flex;
	flex-direction: column;
	width: 80%;
	margin: 0 auto;
	margin-top: 8vh;
}

.oddsboard-chart-game {
	width: 100%;
	margin-bottom: 3vh;
}

.oddsboard-chart-header {
	text-align: left;
	font-size: 1.5em;
	font-weight: 600;
	margin-top: 0vh;
	margin-bottom: 3vh;
	/*background: blue;
	border-right: 1px solid white;*/
}

.oddsboard-chart-body {
	display: flex;
	flex-direction: row;
	/*flex-wrap: wrap;*/
	justify-content: space-between;
	width: 90%;
	margin: 0 auto;
}

.oddschart {
	display: flex;
	position: relative;
	padding-top: 5vh;
	margin: 0 auto;
	/*border: 1px solid white;*/
}

.oddsboard-chart-single {
	position: relative;
	margin-bottom: -1vh;
}

.oddsboard-plot-header {
	position: absolute;
	top: 3vh;
	width: 100%;
	text-align: center;
	font-size: 1.2em;
 /*   text-transform:capitalize;*/
	/*color: #6ae000;*/
}


/* ------------------------------ Promo ------------------------------ */

.oddsboard-promo {
	margin-top: 20vh;
	font-size: 4vh;
	font-family: "Source Code Pro";
}

.oddsboard-promo > div {
	margin-top: 2vh;	
}

.oddsboard-promo > div:first-child {
	margin-bottom: 2vh;
/*	font-family: "";	*/
}

.oddsboard-promo > div:nth-child(2) {
	margin-top: 10vh;	
}

.oddsboard-promo > div:nth-child(3) {
	font-size: 0.7em;	
}

.oddsboard-promo > div:nth-child(4) {
	margin-top: 1vh;	
}

.oddsboard-promo > img:last-child {
	margin-top: 2vh;
	height: 10vh;	
}


/* ------------------------------ Demo controls ------------------------------ */

.oddsboard-demo-header {
	position: fixed;
	top: 1vh;
	right: 4vh;
	color: #ffb300;
	font-size: 4em;
	font-family: "Source Code Pro";
}

.oddsboard-demo-header > div:nth-child(2) {
	font-size: 0.3em;
	font-family: "Montserrat";
	margin: -0.3em 0 0.3em 0;
}

.oddsboard-demo-controls {
	position: fixed;
	display: flex;
	flex-direction: column;
	bottom: 2vh;
	left: 2vw;
	width: 10em;
	text-align: left;
	font-size: 1em;
}

.oddsboard-demo-controls .demo-buttons {
	display: flex;
	justify-content: space-around;
	align-content: center;
}

.oddsboard-demo-controls .icon {
	align-self: center;
	color: #ffb300;
	/*color: #555555;
	color: #b4ff70;*/
	font-size: 1.8em;
	text-align: center;
	cursor: pointer;
	/*background: white;
	border-radius: 3em;
	width: 5vh;
	height: 5vh;*/
}

.oddsboard-demo-login-return {
	display: block;
	height: 2vh;
	margin-top: 1.2em;
	font-size: 1.5vh;
	text-align: center;
	text-decoration: none;
	font-family: "Open Sans";
	color: white;
	/*cursor: pointer;*/
}

.oddsboard-demo-header .oddsboard-demo-login-return {
	margin-top: 0;
}

.oddsboard-demo-controls .icon:nth-child(2) {
	position: relative;
	/*width: 7vh;
	height: 7vh;*/
	font-size: 2.5em;
}

.oddsboard-demo-controls .icon.play {
	left: 0.05em;
}

.oddsboard-demo-controls .icon.pause {
	bottom: 0.02em;
}


/*.oddsboard-demo-controls button {
	height: 4vh;
}

.oddsboard-demo-controls > button {
	margin-top: 0.8em;
	background: #ee1140;
	border-radius: 0;
}*/

/* ------------------------------ Sportspage logo ------------------------------ */

.oddsboard-logo {
	position: fixed;
	display: flex;
	bottom: 2vh;
	right: 2vw;
	text-align: left;
	height: 7vh;
	font-size: 1.5em;
}

.oddsboard-logo > img {
	height: 7vh;
	width: 7vh;
	margin-right: 1vh;
}

.oddsboard-logo > div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: white;
}

.oddsboard-logo > div > div:nth-child(1) {
	color: #ffb300;
	font-size: 0.8em;
}


@media screen and (min-width: 1100px) {



}



