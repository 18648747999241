@import url(//fonts.googleapis.com/css?family=Open+Sans)
@import url(//fonts.googleapis.com/css?family=Rubik)
@import url(//fonts.googleapis.com/css?family=Source+Sans+Pro)
@import url(//fonts.googleapis.com/css?family=News+Cycle)
@import url(//fonts.googleapis.com/css?family=Montserrat)
@import url(//fonts.googleapis.com/css?family=Permanent+Marker)
@import url(//fonts.googleapis.com/css?family=Anton)
@import url(//fonts.googleapis.com/css?family=Source+Code+Pro)
@import url(//fonts.googleapis.com/css?family=Alfa+Slab+One)



* {
  -webkit-font-smoothing: antialiased;
}

html,body {
    width: 100%;
    height: 100%;
    min-width: 600px;
    margin: 0px;
    padding: 0px;
}

body {
  display: fixed;
  position: relative;
  background-attachment: fixed;
  text-align: center;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
}

#root {
	min-width: 600px;
  font-family: "Open Sans", sans-serif;
  text-align: center;
}


button {
  position: relative;
  height: 50px;
	border: none;
	color: white;
	border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  cursor: pointer;
}

button:focus {
  outline: none;
}

button:hover {
  filter: brightness(110%);
}



