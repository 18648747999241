
#react-container {
  width: 100%;
}

.page-holder {
  position: relative;
  width: 100%;
  min-height: 100vh;
  /*padding-top: 65px;
  padding-top: 120px;*/
/*  min-height: calc(100vh - 165px);*/
  z-index: 2;
  background: black;
  color: white;
}

#react-container.dash {
  background: #f3f3f3;
}

#react-container.pric {
	background: #a64643;
	background-image: -webkit-linear-gradient(bottom left, #301023, #6a273a, #a64643, #d87140, #f9a836);
	background-image: -o-linear-gradient(bottom left, #301023, #6a273a, #a64643, #d87140, #f9a836);
	background-image: linear-gradient(to top right, #301023, #6a273a, #a64643, #d87140, #f9a836);
  background-image: linear-gradient(to right top, #3e4449, #5c6369, #7b838a, #9ca5ad, #bec8d1);
  background-image: linear-gradient(to right top, #313e47, #4a5862, #64737e, #7f8f9c, #9cacba);
  background: #2f3338;
  background: #535c68;
  /*background-image: linear-gradient(to right top, #2f3438, #41474c, #545a60, #686f76, #7c848c);*/
 /* background-image: url("/images/home-banner-muted.png");
  background-size: cover;
  background-position: 50% 65%;*/

 /* background-image: url(/static/media/home-banner-muted.4bfdb257.png);
  background-size: cover;
  background-position: 50% 65%;
  opacity: 1;
  -webkit-filter: grayscale(50%);
  filter: grayscale(50%);*/
  
}





