
.terms-page {
	position: relative;
	margin: 0 auto 0 auto;
	padding-bottom: 50px;
	text-align: left;
	font-size: 15px;
	font-weight: lighter;
	/*color: #333;
	color: #444951;*/
}

.terms-page .titan-odds-logo {
	display: flex;
	justify-content: flex-start;
	/*position: relative;
	right: 1em;*/
	width: 100%;
	padding: 0.2em 0 0.5em 0.2em;
	margin-left: 0.5em;
	cursor: pointer;
}

.terms-page .titan-odds-logo img {
	height: 50px;
}

.terms-page-body {
	padding: 1em;
}


.terms-page > div:nth-child(2) {
	padding-top: 5vh;
	max-width: 800px;
	margin: 0 auto 0 auto;
}

.terms-page > hr {
	border-top: 1px solid #eee;
}

.terms-disclaimer {
	/*width: calc(102% - 30px);
	position: relative;
	left: -1%;*/
	margin: 25px 0 20px 0;
	padding: 10px 15px;
	background: #e8eef7;
	font-size: 15px;
/*	color: #000;*/
	border-radius: 5px
}

.terms-disclaimer > .terms-subheader {
	padding-top: 5px;
}

.terms-header {
	margin-bottom: 28px;
	color: #ffb300;
	font-size: 28px;
}

.terms-type-header {
	margin-bottom: 28px;
	color: #f46065;
	font-size: 18px;
}

.terms-subheader {
	padding: 20px 0 0 0;
	font-size: 20px;
	font-weight: normal;
/*	color: #000;
	color: #444951;*/
}

.terms-page p {
	line-height: 22px;
}

.terms-link {
	font-weight: bold;
	font-weight: normal;
	color: #960939;
	color: #0099e5;
	color: #22cca7;
	text-decoration: underline;
	cursor: pointer;
}



