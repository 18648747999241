.contact-form {
	margin-top: 8vh;
}

.contact-form-header {
	color: #ffb300;
	font-size: 1.6em;
	text-align: center;
	margin-bottom: 5vh;
}

.contact-form-header.questions {
	margin-bottom: 4vh;
}

.contact-form form {
	display: inline-block;
	position: relative;
	margin-top: 1vh;
	width: 100%;
	max-width: 400px;
}

.contact-form label {
	float: left;
	font-size: 1em;
	text-align: left;
	margin-top: 0.5em;
}

.contact-form input[type=text], .contact-form input[type=password] {
	width: 100%;
	display: inline-block;
	border: 1px solid #ccc;
	padding: 10px;
	border-radius: 4px;
	box-sizing: border-box;
	margin-top: 0.2em;
}

.contact-form textarea {
	width: 100%;
	height: 20vh;
	display: inline-block;
	border: 1px solid #ccc;
	padding: 10px;
	border-radius: 4px;
	box-sizing: border-box;
	margin-top: 0.2em;
	word-wrap: break-word;
    word-break: break-all;
    overflow: auto;
}

/* Style the submit button */
.contact-form input[type=submit] {
	margin-top: 1.5vh;
	min-width: 100%;
	padding: 12px;
	border-radius: 5px;
	border: none;
	font-size: 16px;
	cursor: pointer;
	text-align: center;
	background-color: #ee1140;
	color: white;
	font-weight: bold;
	font-family: "Montserrat";
}

.contact-form input[value=Logout] {
	padding: 8px;
	background-color: #777777;
}

#contact-form-response {
	margin-top: 0.5em;
	color: #ffb300;
	opacity: 1;
}

#contact-form-response.hide {
	opacity: 0;
}


