@import url(//fonts.googleapis.com/css?family=Open+Sans)@import url(//fonts.googleapis.com/css?family=Rubik)
@import url(//fonts.googleapis.com/css?family=Source+Sans+Pro)
@import url(//fonts.googleapis.com/css?family=News+Cycle)
@import url(//fonts.googleapis.com/css?family=Montserrat)
@import url(//fonts.googleapis.com/css?family=Permanent+Marker)
@import url(//fonts.googleapis.com/css?family=Anton)
@import url(//fonts.googleapis.com/css?family=Source+Code+Pro)
@import url(//fonts.googleapis.com/css?family=Alfa+Slab+One)



*
html,body {
    width: 100%;
    height: 100%;
    min-width: 600px;
    margin: 0px;
    padding: 0px;
}

body {
  display: fixed;
  position: relative;
  background-attachment: fixed;
  text-align: center;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
}

#root {
	min-width: 600px;
  font-family: "Open Sans", sans-serif;
  text-align: center;
}


button {
  position: relative;
  height: 50px;
	border: none;
	color: white;
	border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  cursor: pointer;
}

button:focus {
  outline: none;
}

button:hover {
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}




.nav-outer {
	/*position: relative;
	z-index: 5;*/
}

.nav-top {
	background: white;
	height: 65px;
}

.nav-top-header {
	margin-right: 5%;
	font-family: Montserrat;
	font-size: 18px;
	font-size: 14px;
	color: #999;
	color: #c97922;
	/*font-weight: bold;*/
	letter-spacing: 1.8px;
}

.navbar {
	/*position: fixed;*/
    width: 100%;
    min-width: 600px;
    background: white;
    background: #2f3338;
    z-index: 4;
    box-shadow: 0 2px 3px rgba(0,0,0,0.2);
}

.navbar-inner {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: relative;
	width: 100%;
	max-width: 1400px;
	height: 65px;
	margin: 0 auto;
	/*background: white;*/
}

.navbar-inner.links {
	height: 55px;
}

.menu-button {
	height: 30px;
	width: 65px;
	margin-left: 10px;
	padding: 0;
	background: #0ea4f4;
	background: #309cb7;
	background: #22cca7;
	text-align: center;
	font-size: 13px;	
	box-shadow: none;
	cursor: pointer;
}

.navbar-links {
	padding-right: 10px;
}

.navbar-link {
	align-self: center;
	position: relative;
	margin: 0 8px;
	margin: 0 15px;
	font-size: 14px;
	font-size: 16px;
	font-weight: 500;
	color: #999;
	color: white;
	text-decoration: none;
	cursor: pointer;
}

.nav-logo {
	height: 75%;
    margin: 0 auto 0 10px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}

.nav-logo img {
	height: 40px;
    margin-top: 4.5%;
}


@media screen and (min-width: 1000px) {

	.nav-top-header {
		margin-right: 10%;
		font-size: 15px;
		letter-spacing: 3px;
	}


}




.home {
	font-family: Montserrat;
	min-width: 600px;
	padding-bottom: 20px;
}

.home-banner {
	position: relative;
	width: 100%;
	height: 400px;
	margin-top: -1px;
	background: #2f3338;
	overflow-y: hidden;
}

.home-banner.loaded {
	/*background: #4c031c;*/
}

.home-banner-inner {
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: url(../../static/media/home-banner-muted.4bfdb257.png);
	background-size: cover;
	background-position: 50% 65%;
	opacity: 0.5;
    -webkit-filter: grayscale(50%);
            filter: grayscale(50%);
}

.banner-content {
	width: 90%;
	max-width: 800px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	margin-top: 120px;
	opacity: 0;
}

.banner-content.loaded {
	opacity: 1;
}

.home-banner-right-holder {
	display: flex;
	flex-direction: column;
	position: relative;
	color: #22cca7;
	color: #ffb300;
	color: white;
	width: 47%;
	text-align: left;
	font-size: 18px;
	font-family: Montserrat;
	margin-top: -5px;
}


.home-banner-left {
	position: relative;
	font-size: 28px;
	color: white;
	font-family: "Alfa Slab One";
	letter-spacing: 2px;
	text-align: left;
	margin-right: 30px;
}

.home-banner-left:nth-child(3) {
	font-size: 50px;
}

.banner-logo {
	position: relative;
	width: 100%;
	max-width: 800px;
	
}

.home-banner-secondary {
	position: relative;
	margin-top: 15px;
	font-size: 20px;
	font-weight: bold;
	color: white;
}

.home button {
	position: relative;
	background: #0ea4f4;
	background: #309cb7;
	background: #22cca7;
	background: #15afe0;
	background: #e01542;
	/*border: 1px solid #5df4d3;
	border-top: 0px solid #5df4d3;*/
	width: 90%;
	height: 60px;
	margin-top: 15px;
	font-size: 18px;
	font-weight: 500;
	text-align: center;
	color: white;
	border-radius: 5px;
	box-shadow: inset 0 1px 2px rgba(255,255,255,0.3);
}

.home-sub-banner {
	overflow: hidden;
	height: 300px;
	background: #ffb300;
}

.home-sub-banner > h2 {
	margin-top: 40px;
	font-family: "Montserrat";
}

.why-sportsfeed-outer {
	position: relative;
	margin: 0 auto;
	padding: 30px 0;
	/*box-shadow: 0 2px 3px rgba(0,0,0,0.1);
	z-index: 5;*/
	border-bottom: 2px solid #eee;
}

.why-sportsfeed-holder {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	position: relative;
	max-width: 1100px;
	margin: 10px auto;
	color: #555555;
}

.why-sportsfeed {
	flex: 1 1;
	position: relative;
	width: 200px;
	/*padding-bottom: 22px;*/
	/*background: #ad2033;*/
	/*color: white;*/
	/*padding-top: 20px;*/
}

/*
.why-sportsfeed:nth-child(1) {
	background: #991a2b;
}

.why-sportsfeed:nth-child(3) {
	background: #c61f36;
}*/

.why-sportsfeed-inner {
	position: relative;
	width: 80px;
	height: 80px;
	margin: auto;
	background-color: #88ff1a;
	background-image: linear-gradient(132deg, #aeff64 0%, #00c30f 100%);
	background-image: linear-gradient(150deg, #64eaff 0%, #0076c3 100%);
	border-radius: 8px;
}

.why-sportsfeed-inner > i {
	
	padding-top: 12px;
	font-size: 3em;
	color: white;
	margin-left: 2px;
}

.why-sportsfeed:nth-child(2) i {
	
	margin-left: 1px;
	font-size: 2.8em;
	padding-top: 13px;
}

/*.why-sportsfeed-inner > div {
	display: block;
	position: relative;
	width: 75%;
	margin: 15px auto 0 auto;
	font-size: 1em;
	font-weight: bold;
	line-height: 20px;
}*/

.why-sportsfeed-text {
	width: 80%;
    margin: 10px auto 0 ;
	font-size: 14px;
	font-weight: 500;
}

.why-sportsfeed-content {
	width: 80%;
    margin: 10px auto 0 ;
	color: #aaaaaa;
	font-size: 14px;
	line-height: 1.4;
}

.home-section {
	display: flex;
	position: relative;
	padding-bottom: 100px;
	/*height: 500px;*/
	background: #f7f7f7;
	border-bottom: 2px solid #eee;
}

.home-section:nth-child(2) {
	border-bottom: none;
}

.home-section-background {
	display: flex;
	position: absolute;
	height: 100%;
	width: 100%;
}

.home-section-background-left {
	flex: 1 1;
	height: 100%;
	
}

.home-section-background-right {
	flex: 0 1;
	height: 100%;
	background-color: #2e4057;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='75' height='75' viewBox='0 0 120 120'%3E%3Cpolygon fill='%2329394d' points='120 120 60 120 90 90 120 60 120 0 120 0 60 60 0 0 0 60 30 90 60 120 120 120 '/%3E%3C/svg%3E");
	background-size: 90%;
	background-position: 50% 45%;
	/*opacity: 0;*/
	/*filter: grayscale(100%);*/
}

.home-section-background-right::after {
	/*flex: 30% 0;*/
	/*position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	background: blue;
	z-index: */
	/*background-image: url("../../images/steph.png");
	background-size: 200%;
	background-position: 50% 45%;*/
	/*filter: grayscale(100%);*/
}

.home-section-background-right.yellow {
	background: #F9E900;
	background: #ffb300;
	-webkit-filter: grayscale(0%);
	        filter: grayscale(0%);
}

.home-section-foreground {
	display: flex;
	justify-content: space-between;
	position: relative;
	max-width: 1100px;
	margin: 50px auto 0 auto;
	padding: 20px;
	/*width: calc(100% - 550px);
	width: calc(85% - 175px);
	width: calc(65% + 200px);
	margin-top: 80px;
	margin-left: 150px;
	margin-left: 5%;*/
	/*overflow: hidden;
	padding-bottom: 15px;*/
}

.home-section-foreground.lower {
	width: calc(95%);
	margin-top: 40px;

}

.home-section-foreground .tablet {
	/*display: inline-block;
	position: relative;*/
	width: 55%;
	min-width: 55%;
	/*height: 100%;*/
	position: relative;
	/*flex: 0 1 500px;
	width: 500px;
	max-width: 500px;*/
	align-self: flex-start;
	margin-top: 35px;
	-webkit-filter: drop-shadow(0px 2px 5px rgba(0,0,0,0.7));
	        filter: drop-shadow(0px 2px 5px rgba(0,0,0,0.7));
	/*box-shadow: 0 5px 15px rgba(0,0,0,0.3);
	background: none;*/
}




.home-line-chart-header {
	width: 95%;
	max-width: 1100px;
	margin: 40px auto 10px auto;
	text-align: left;
	font-family: "Montserrat";
	color: #bbb;
	font-size: 20px;
}


.home-line-chart {
	width: 95%;
	max-width: 1000px;
	margin: 0 auto;
	/*border: 1px solid black;*/
	/*padding: 10px;*/
	/*box-shadow: 0 2px 5px rgba(0,0,0,0.2);*/
	padding-bottom: 10px;
}

.home-line-chart canvas {
	margin: 20px;
	max-width: 960px;
	margin-top: 10px;
}


.home-chart-top {
	/*width: calc(100% + 10px);
	position: relative;
	top: -10px;
	left: -10px;*/
	background: black;
	color: white;
	padding: 10px;
}

.home-chart-header {
	text-align: left;
}

.home-chart-header em {
	font-weight: bold;
	font-style: normal;
}

.home-chart-subheader {
	text-align: left;
	font-size: 12px;
	margin-top: 5px;
}

.home-details-holder {
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 1000px;
	margin: 50px auto;
	padding-bottom: 50px;
	border-bottom: 2px solid #eee;
}

.home-details-holder.first {
	padding-top: 50px;
	border-top: 2px solid #eee;
}

.home-details {
	/*width: 90%;
	max-width: 900px;*/
	/*width: 40%;*/
	/*margin: 0 auto;*/
	font-size: 1.1em;
	color: #485f70;
	text-align: left;
	/*margin-top: 2.5em;
	margin-top: calc(3.5em - 30px);*/
	align-self: center;
	margin-right: 5%;
	/*padding: 120px 0;*/
	/*padding-left: 10%;*/
	font-family: Rubik;
	/*background: blue;*/
	/*color: #444951;*/
	/*border-bottom: 1px solid #eee;*/
}



.details-header {
	margin-bottom: 10px;
	font-size: 1.5em;
	font-weight: 400;
}

.details-body {
	/*font-family: "Montserrat";*/
	line-height: 28px;
}

.home-details button {
	position: relative;
	margin: 20px auto 0 auto;
	background: #d85057;
	background: #c97922;
	background: none;
	width: 180px;
	height: 50px;
	color: #485f70;
	border: none;
	border: 3px solid #485f70;
	/*color: #991a2b;
	border: 4px solid #991a2b;*/
	border-radius: 0;
	box-shadow: none;
	/*border-radius: 3px;
	box-shadow: 0 1px 5px rgba(0,0,0,0.2);*/
	/*background-image: url("../../images/button-wave-2.png");
	background-size: cover;
	background-position: 50% 65%;*/
	
	/*background: #d83c2b;*/
	/*background: #c61f36;*/
	/*background: linear-gradient(to bottom, #d83c2b, #7c1c11);
	border: 2px solid #7f1f15;
	border-top: 1px solid #7f1f15;
	box-shadow: inset 0 1px 2px rgba(255,255,255,0.5);*/
}

.home-details button:hover {
	background: #485f70;
	color: white;
}

.widgets-holder {
	/*position: relative;
	width: 90%;
	max-width: 1000px;
	margin: 0 auto;
	margin-top: 100px;
	display: flex;
	justify-content: space-around;
	justify-content: center;*/
	/*margin-right: 5%;*/
}

.score-widget {
	width: 250px;
	min-width: 250px;

	margin-left: 150px;
	margin-left: 0;
	/*margin-right: 5px;*/
	background: white;
	font-size: 14px;
	text-align: left;
	box-shadow: 0 5px 15px rgba(0,0,0,0.3);
	max-height: 350px;
}

.score-widget.squeeze {
	display: none;
}

.score-widget-header {
	background: #0b75bc;
	background: #233e51;
	background: linear-gradient(#668499, #233e51);
	background: #2f3338;
	background: black;
	color: white;
	padding: 10px 15px;
	font-family: "Open Sans";
}

/*.score-widget:nth-child(2) .score-widget-header {
	filter: brightness(120%);
}

.score-widget:nth-child(3) .score-widget-header {
	filter: brightness(140%);
}*/

.score-widget-header-date {
	/*font-size: 16px;*/
}

.score-widget-header-venue {
	font-size: 0.7em;
	margin-top: 0.25em;
}

.score-widget-matchup {
	padding: 0 15px 10px 15px;
	/*height: 8em;*/
}

.score-widget-team {
	margin-top: 1.5em;
	display: flex;
	justify-content: space-between;
	height: 50px;
}

.score-widget-team:first-child {
	margin-top: 1em;
}

.score-widget-team-left {
	display: flex;
}

.score-widget-team-left img {
	width: 1.8em;
	height: 1.8em;
	margin-right: 10px;
}

.score-widget-team-name {
	font-size: 1.2em;
	font-weight: 500;
}

.score-widget-team-mascot {
	/*font-size: 14px;*/
	font-size: 0.9em;
	font-weight: 500;
}

.score-widget-team-name span {
	margin-left: 10px;
	font-size: 0.6em;
	color: #999;
}

.score-widget-team-odds {
	font-size: 0.8em;
	margin-top: 0.2em;
	color: #999;
}

.score-widget-team-score {
	margin-top: -6px;
	font-size: 2.5em;
}

.score-widget-total {
	margin-top: 1.5em;
	color: #999;
	text-align: right;
}

.score-widget-footer {
	/*background: #cfeafc;*/
	display: flex;
	justify-content: space-between;
	position: relative;
	border-top: 1px solid #eee;
	color: #999;
	padding: 5px 8px;
	max-height: 50px;
}

.score-widget-footer img {
	height: 32px;
	opacity: 0.5;
}

.score-widget-footer-status {
	margin-right: 5px;
	align-self: center;
}

.home-contact-link {
	display: inline-block;
	margin-top: 10px;
	color: #c97922;
	text-decoration: underline;
	/*cursor: pointer;*/
/*	filter: brightness(80%);*/
}

.home-contact-link:hover {
	-webkit-filter: brightness(110%);
	        filter: brightness(110%);
}

.home-superfooter {
	background: #22cca7;
	background: #9aa9ba;
	color: white;
	padding: 40px;
}

.home-superfooter .home-details {
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
	color: white;
	text-align: center;
	font-weight: 100;
}	

.home-superfooter .details-body {
	font-size: 20px;
}

.home-superfooter button {
	color: white;
	border: 3px solid white;
}

.home-superfooter button:hover {
	background: white;
	color: #9aa9ba;
}


@media screen and (min-width: 650px) {

	.home-banner-secondary {
		font-size: 24px;
	}

	.home-banner-left {
		font-size: 30px;
	}

	.home-banner-left:nth-child(3) {
		font-size: 55px;
	}

	.home-banner-right {
		font-size: 20px;
	}

}


@media screen and (min-width: 800px) {

	.home-banner-secondary {
		font-size: 24px;
	}

	.home-banner-left {
		position: relative;
		font-size: 40px;
		color: white;
		font-family: "Alfa Slab One";
		letter-spacing: 2px;
		text-align: left;
		margin-right: 30px;
	}

	.home-banner-left:nth-child(3) {
		font-size: 65px;
	}

	.home-banner-right {
		font-size: 24px;
	}

	.home-section-background-right {
		flex: 30% 0;
	}

	.home-section-foreground.lower {
		width: 90%;
	}

	.home-section-foreground .tablet {
		align-self: flex-start;
		margin-top: 0;
		margin-right: -5px;
	}

	.widgets-holder {
		margin-right: 0;
	}

	.score-widget {
		width: 400px;
		min-width: 400px;
		font-size: 18px;
	}

	.score-widget-total {
		margin-top: 2.5em;
	}

}

@media screen and (min-width: 1000px) {

	.home-banner-secondary {
		font-size: 24px;
	}

	.home-banner-left {
		position: relative;
		font-size: 40px;
		color: white;
		font-family: "Alfa Slab One";
		letter-spacing: 2px;
		text-align: left;
		margin-right: 30px;
	}

	.home-banner-left:nth-child(3) {
		font-size: 65px;
	}

}






.contact-form {
	margin-top: 8vh;
}

.contact-form-header {
	color: #ffb300;
	font-size: 1.6em;
	text-align: center;
	margin-bottom: 5vh;
}

.contact-form-header.questions {
	margin-bottom: 4vh;
}

.contact-form form {
	display: inline-block;
	position: relative;
	margin-top: 1vh;
	width: 100%;
	max-width: 400px;
}

.contact-form label {
	float: left;
	font-size: 1em;
	text-align: left;
	margin-top: 0.5em;
}

.contact-form input[type=text], .contact-form input[type=password] {
	width: 100%;
	display: inline-block;
	border: 1px solid #ccc;
	padding: 10px;
	border-radius: 4px;
	box-sizing: border-box;
	margin-top: 0.2em;
}

.contact-form textarea {
	width: 100%;
	height: 20vh;
	display: inline-block;
	border: 1px solid #ccc;
	padding: 10px;
	border-radius: 4px;
	box-sizing: border-box;
	margin-top: 0.2em;
	word-wrap: break-word;
    word-break: break-all;
    overflow: auto;
}

/* Style the submit button */
.contact-form input[type=submit] {
	margin-top: 1.5vh;
	min-width: 100%;
	padding: 12px;
	border-radius: 5px;
	border: none;
	font-size: 16px;
	cursor: pointer;
	text-align: center;
	background-color: #ee1140;
	color: white;
	font-weight: bold;
	font-family: "Montserrat";
}

.contact-form input[value=Logout] {
	padding: 8px;
	background-color: #777777;
}

#contact-form-response {
	margin-top: 0.5em;
	color: #ffb300;
	opacity: 1;
}

#contact-form-response.hide {
	opacity: 0;
}




.oddsboard {
	position: relative;
	width: 100%;
	min-width: 600px;
	background: black;
	height: 100vh;
	color: white;
	/*color: #6ae000;*/
	background: #000000;
	/*background: radial-gradient(circle, rgba(34,32,32,1) 0%, rgba(0,0,0,1) 100%);*/
	/*background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 100 100'%3E%3Crect x='0' y='0' width='60' height='60' fill-opacity='0.38' fill='%23666666'/%3E%3C/svg%3E");
	*/opacity: 1;
	/*background-image: url("");*/
}


.test {
	position: absolute;
	top: 0;
	color: white;
}

.oddsboard-background {
	position: relative;
	width: 100%;
	min-width: 600px;

	background-image: url("https://wallpaper.dog/large/10861237.png");
	background-image: url(../../static/media/led-background.754b262a.png);
	background-size: cover;
	background-attachment: fixed;
	opacity: 0.3;
	height: 100vh;
}

#oddsboard-body {
	position: fixed;
	top: 0;
	width: 100%;
	min-width: 600px;
	height: 100vh;
	overflow-y: scroll;
}

#oddsboard-body, #oddsboard-data-body {
	opacity: 1;
	-webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
}



#oddsboard-body.hide, #oddsboard-data-body.hide {
	opacity: 0;
	-webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
}

.oddsboard-login {
	position: relative;
	width: 50%;
	margin: 0 auto;
	top: 10vh;
	
	padding-bottom: 10vh;
}

.oddsboard-login-header {
	font-size: 2em;
	font-family: "Alfa Slab One";
	margin-bottom: 5vh;
	/*font-family: "Permanent Marker";
	font-family: "Montserrat";*/
}

.oddsboard-login form {
	display: inline-block;
	position: relative;
	margin-top: 1vh;
	width: 100%;
	max-width: 400px;
}

.oddsboard-login label {
	float: left;
	font-size: 1em;
	text-align: left;
	margin-top: 0.5em;
}

.oddsboard-login-form-lower {
	display: flex;
	flex-direction: row;
}

.oddsboard-login .checkbox-holder {
    display: flex;
    flex-direction: row;
    width: 40%;
   /* align-content: right;
    justify-content: right;*/
    /*position: absolute;
    right: 0;*/
    justify-content: flex-end;
    vertical-align: middle;
    font-size: 0.8em;
    margin-top: 0.5em;
}

.oddsboard-login .checkbox-holder > label {
    margin: 0 0.3em 0 0;
}

.oddsboard-login .checkbox-holder > input {
    cursor: pointer;
}

.oddsboard-login label#login-error {
	width: 100%;
	text-align: center;
	color: #ffb300;
	font-size: 0.8em;
	margin-top: 0.5em;
	opacity: 0;
}

.oddsboard-login label#login-error.show {
	opacity: 1;
}

.oddsboard-password-holder > div:last-child {
	position: relative;
}

.oddsboard-password-holder > div:last-child {
	position: absolute;
    top: 1.2vh;
    right: 0;
    font-size: 0.8em;
    color: white;
    cursor: pointer;
}


.oddsboard-login input[type=text], .oddsboard-login input[type=password] {
	width: 100%;
	display: inline-block;
	border: 1px solid #ccc;
	padding: 10px;
	border-radius: 4px;
	box-sizing: border-box;
	margin-top: 0.2em;
}

.oddsboard-login textarea {
	width: 100%;
	height: 20vh;
	display: inline-block;
	border: 1px solid #ccc;
	padding: 10px;
	border-radius: 4px;
	box-sizing: border-box;
	margin-top: 0.2em;
	word-wrap: break-word;
    word-break: break-all;
    overflow: auto;
}

/* Style the submit button */
.oddsboard-login input[type=submit] {
	margin-top: 1.5vh;
	min-width: 100%;
	padding: 12px;
	border-radius: 5px;
	border: none;
	font-size: 16px;
	cursor: pointer;
	text-align: center;
	background-color: #ee1140;
	color: white;
	font-weight: bold;
	font-family: "Montserrat";
}

.oddsboard-login input[value=Logout] {
	padding: 8px;
	background-color: #777777;
}

/* Add a background color to the submit button on mouse-over */
.oddsboard-login input[type=submit]:hover {
  background-color: #ffb300;
  background-color: #ff194a;
}

.oddsboard-login-instructions {
	margin-top: 5vh;
	text-align: left;
}

.oddsboard-login-instructions li {
	margin-bottom: 2vh;
}

.oddsboard-login-instructions a {
	margin-left: 0.3em;
	color: #ffb300;
}

.oddsboard-login-instructions span {
	margin-left: 0.3em;
	font-style: italic;
}

.oddsboard-instructions-section {
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-bottom: 3vh;
	line-height: 1.2;
}

.oddsboard-data-reminder {
	margin-top: 5vh;
	font-size: 0.9em;
	line-height: 1.3em;

}

.oddsboard-data-reminder > div:first-child {
	color: #ffb300;
/*	color: #ee1140;*/
	margin-bottom: 0.5vh;
}

.oddsboard-instructions-num {
	font-size: 1.5em;
	font-family: "Alfa Slab One";
}

.oddsboard-instructions-section-body {
	margin-left: 1.5em;
}

.oddsboard-instructions-section-body > div:nth-child(1) {
	font-size: 1.5em;
	font-family: "Alfa Slab One";
	margin-bottom: 0.3em;
}

/* ------------------------------ Schedule ------------------------------ */

#oddsboard-schedule {
	position: relative;
	width: 70%;
	margin: 0 auto;
	padding-top: 5vh;
	font-size: 1.8em;
	height: 90vh;
	overflow-y: scroll;
}

#oddsboard-schedule > div {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: flex-start;
	position: relative;
	width: 100%;
	height: 90vh;
}

.odds-game-status {
	position: relative;
	width: 10vh;
	text-align: left;
	margin-right: 2vh;
	font-size: 0.7em;
	margin-top: 0.15em;
	color: white;
}

.odds-game-status > div {
	position: absolute;
	top: 0;
	left: 0;
}

.odds-teams {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	text-align: left;
	color: #6ae000;
}

.odds-teams > div {
	display: flex;
}

.odds-teams > div > div:nth-child(2) {
	margin-left: auto;
}

.odds-teams span {
	position: relative;
	/*bottom: 0.05em;*/
	margin-left: 0.3em;
	font-size: 0.8em;
}

.oddsboard-league {
	display: fixed;
	position: relative;
	text-align: left;
	top: 2vh;
	left: 5vh;
	font-size: 3em;
	font-weight: bold;
}

.oddsboard-game {
	display: flex;
	width: 45%;
	padding: 1vh 0;
	border-bottom: 1px solid rgba(255,255,255,1);
	font-size: 1.1em;
/*	border-bottom: 1px solid #6ae000;*/
}

.flash-enter {
  opacity: 0;
}
.flash-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out 500ms;
}
.flash-exit {
  opacity: 1;
}
.flash-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.odds-game-right {
	position: relative;
	margin-left: auto;
	text-align: right;
	color: #6ae000;
	height: 2.2em;
}

.odds-game-right > div {
	position: absolute;
}

.oddsgame-team {
	position: relative;
}

.oddsgame-team span {
	font-size: 0.6em;
}

.odds-game-score {
	position: absolute;
	right: 0;
	color: white;
	font-weight: bold;
}

/*.odds-game-right > div > div {
	position: relative;
}

.odds-game-right > div > div div {
	position: absolute;
	top: 0;
	right: 0;
	height: 1.2em;
}*/

/*.odds-game-right > div > div:first-child {
	position: absolute;
	right: 0;
	top: 0;
}*/

.odds-game-right > div > div:last-child div {
	bottom: 0;
}

.oddsboard-chart-holder {
	display: flex;
	flex-direction: column;
	width: 80%;
	margin: 0 auto;
	margin-top: 8vh;
}

.oddsboard-chart-game {
	width: 100%;
	margin-bottom: 3vh;
}

.oddsboard-chart-header {
	text-align: left;
	font-size: 1.5em;
	font-weight: 600;
	margin-top: 0vh;
	margin-bottom: 3vh;
	/*background: blue;
	border-right: 1px solid white;*/
}

.oddsboard-chart-body {
	display: flex;
	flex-direction: row;
	/*flex-wrap: wrap;*/
	justify-content: space-between;
	width: 90%;
	margin: 0 auto;
}

.oddschart {
	display: flex;
	position: relative;
	padding-top: 5vh;
	margin: 0 auto;
	/*border: 1px solid white;*/
}

.oddsboard-chart-single {
	position: relative;
	margin-bottom: -1vh;
}

.oddsboard-plot-header {
	position: absolute;
	top: 3vh;
	width: 100%;
	text-align: center;
	font-size: 1.2em;
 /*   text-transform:capitalize;*/
	/*color: #6ae000;*/
}


/* ------------------------------ Promo ------------------------------ */

.oddsboard-promo {
	margin-top: 20vh;
	font-size: 4vh;
	font-family: "Source Code Pro";
}

.oddsboard-promo > div {
	margin-top: 2vh;	
}

.oddsboard-promo > div:first-child {
	margin-bottom: 2vh;
/*	font-family: "";	*/
}

.oddsboard-promo > div:nth-child(2) {
	margin-top: 10vh;	
}

.oddsboard-promo > div:nth-child(3) {
	font-size: 0.7em;	
}

.oddsboard-promo > div:nth-child(4) {
	margin-top: 1vh;	
}

.oddsboard-promo > img:last-child {
	margin-top: 2vh;
	height: 10vh;	
}


/* ------------------------------ Demo controls ------------------------------ */

.oddsboard-demo-header {
	position: fixed;
	top: 1vh;
	right: 4vh;
	color: #ffb300;
	font-size: 4em;
	font-family: "Source Code Pro";
}

.oddsboard-demo-header > div:nth-child(2) {
	font-size: 0.3em;
	font-family: "Montserrat";
	margin: -0.3em 0 0.3em 0;
}

.oddsboard-demo-controls {
	position: fixed;
	display: flex;
	flex-direction: column;
	bottom: 2vh;
	left: 2vw;
	width: 10em;
	text-align: left;
	font-size: 1em;
}

.oddsboard-demo-controls .demo-buttons {
	display: flex;
	justify-content: space-around;
	align-content: center;
}

.oddsboard-demo-controls .icon {
	align-self: center;
	color: #ffb300;
	/*color: #555555;
	color: #b4ff70;*/
	font-size: 1.8em;
	text-align: center;
	cursor: pointer;
	/*background: white;
	border-radius: 3em;
	width: 5vh;
	height: 5vh;*/
}

.oddsboard-demo-login-return {
	display: block;
	height: 2vh;
	margin-top: 1.2em;
	font-size: 1.5vh;
	text-align: center;
	text-decoration: none;
	font-family: "Open Sans";
	color: white;
	/*cursor: pointer;*/
}

.oddsboard-demo-header .oddsboard-demo-login-return {
	margin-top: 0;
}

.oddsboard-demo-controls .icon:nth-child(2) {
	position: relative;
	/*width: 7vh;
	height: 7vh;*/
	font-size: 2.5em;
}

.oddsboard-demo-controls .icon.play {
	left: 0.05em;
}

.oddsboard-demo-controls .icon.pause {
	bottom: 0.02em;
}


/*.oddsboard-demo-controls button {
	height: 4vh;
}

.oddsboard-demo-controls > button {
	margin-top: 0.8em;
	background: #ee1140;
	border-radius: 0;
}*/

/* ------------------------------ Sportspage logo ------------------------------ */

.oddsboard-logo {
	position: fixed;
	display: flex;
	bottom: 2vh;
	right: 2vw;
	text-align: left;
	height: 7vh;
	font-size: 1.5em;
}

.oddsboard-logo > img {
	height: 7vh;
	width: 7vh;
	margin-right: 1vh;
}

.oddsboard-logo > div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: white;
}

.oddsboard-logo > div > div:nth-child(1) {
	color: #ffb300;
	font-size: 0.8em;
}


@media screen and (min-width: 1100px) {



}




#sportspage-odds {
	position: relative;
	width: 100%;
	min-width: 800px;
	min-height: 100vh;
	background: black;
	color: white;
/*	overflow-y: scroll;*/
	padding-bottom: 5vh;
}
/*
.sportspage-odds-background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: black;
}*/

.sportspage-odds-page-header {
	position: relative;
	width: 100%;
	height: 150px;
	min-height: 120px;
	background: #222222;
}

.sportspage-odds-header-inner {
	display: flex;
	position: relative;
	top: 15px;
	width: 95%;
	height: 120px;
	max-width: 1200px;
	margin: auto;
	background: black;
	overflow-x: scroll;
	overflow-y: hidden;
}

.odds-header-logo {
	margin-right: 5px;
}

.odds-header-logo-book {
	display: flex;
	flex-direction: column;
	align-content: space-between;
	width: 12vw;	
	height: 120px;
	min-width: 180px;
/*	margin: 5% 0;*/
	background: white;
	color: black;
	font-size: 0.8em;
	/*background: blue;*/
}

.odds-header-logo-book > div:first-child {
	display: flex;
	height: 90px;
}

.odds-header-logo-book > div:last-child {
	height: 30px;
	background: #777777;
	line-height: 30px;
	color: white;
	font-size: 1.2em;
	font-family: "Alfa Slab One";
}

.odds-header-logo-book > div > div {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.odds-header-logo img {
	margin: auto 0;
	width: 6vw;
	min-width: 90px;
/*	height: 4vw;*/
	background: white;
}

.odds-header-logo-view-all {
	display: flex;
	position: relative;
	width: 8vw;
	min-width: 100px;
	height: 100%;
	text-align: center;
	background: #ff4c4c;
	background: #f33232;
	cursor: pointer;
	/*margin: 5% 0;*/
}

.odds-header-logo-view-all > div {
	display: inline-block;
	align-self: center;
	width: 100%;
	font-size: 0.8em;
}

.odds-compare-body {
/*	display: flex;
	flex-direction: column;*/
	position: relative;
	width: 100%;
	/*max-width: 1200px;*/
	margin: 0vh auto;
	margin-bottom: 5vh;
}

.titan-odds-logo {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
}

.titan-odds-logo img {
	height: 50px;
	padding: 0.7em 0.5em 0.5em 0.2em;
}

.odds-compare-body-header-wrapper {
	position: relative;
	height: 80px;
}

#odds-compare-body-header {
	display: flex;
	justify-content: center;
	position: relative;
	top: 0;
	box-shadow: 0 3px 3px #000000;
	margin-bottom: 1em;
	z-index: 1;
}

#odds-compare-header-background {
	display: flex;
	justify-content: space-between;
	/*position: absolute;*/
	/*width: calc(100%-1em);*/
	width: 100%;
	min-width: 800px;
	max-width: 1200px;
	top: 0;
	left: 0;
	background: #222222;
	/*height: calc(65px + 1em);*/
/*	background: #222222;*/
/*	-webkit-transition: background 0.2s ease-in-out;
    -moz-transition: background 0.2s ease-in-out;
    -ms-transition: background 0.2s ease-in-out;
    -o-transition: background 0.2s ease-in-out;*/
}

.sticky-header #odds-compare-body-header {
	position: sticky;
	position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    background: white;
    position: fixed;
    left: 0;
    right: 0;
}

.sticky-header #odds-compare-header-background {
	background: transparent;
	/*display: flex;
	justify-content: space-between;
	top: 0;
	left: 0;
	padding: 0.5em;*/
}

.sticky-header .odds-compare-body-header-book {
	position: relative;
	top: -1px;
	left: -1px;
	border: 1px solid black;
/*	width: calc(6vw + 2px);
	min-width: 88px;*/
	margin-right: 3px;
}

.odds-compare-nav {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: calc(25% - 0.5em + 5px);
	min-width: 200px;
	z-index: 1;
	padding: 0.5em 0 0 0.5em;
/*	background: blue;*/
	/*position: absolute;
	top: 0;
	left: 0;*/
}

.odds-compare-nav select {
	width: 70%;
	background: black;
	color: white;
	margin-bottom: 0.5em;
}

.odds-compare-right {
	position: absolute;
	top: 0;
	left: 25%;
	z-index: 1;
}

.odds-compare-body-header-books {
	display: flex;
	width: 75%;
	padding: 0.5em 0;
}

.odds-compare-body-header-book {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	width: 6vw;
	height: 4vw;
	min-width: 90px;
	min-height: 60px;
	margin-right: 5px;
	background: white;
	color: black;
}

.odds-compare-body-header-book img {
	align-self: center;
	position: relative;
	width: 5.5vw;
	min-width: 80px;
}
.odds-compare-body-header-book div {
	position: relative;
	width: 6vw;
	min-width: 90px;
	line-height: 4vw;
	font-family: Anton;
	/*font-family: Rubik;
	font-weight: 600;*/
	font-size: 2em;
}

.odds-compare-body-games {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	margin-top: 3vh;
	/*background: black;*/
}

.odds-compare-body-top-buffer {
	height: 70px;
}

.odds-compare-no-games {
	margin-top: 100px;
}

.odds-compare-body-games-section {
	margin-bottom: 1em;
}

.odds-compare-section-date {
	position: relative;
	margin-bottom: 0.2em;
	text-align: left;
	font-size: 1.5em;
	font-family: "Rubik";
}

.best-odds-explainer {
	display: flex;
	justify-content: flex-end;
	align-content: center;
	position: absolute;
	right: 0;
	bottom: 0.2em;
	width: 20%;
	height: 70%;
	font-size: 0.5em;
}

.best-odds-explainer > div {
/*	display: inline-block;
	text-align: center;*/
/*	vertical-align: middle;
	align-self: center;*/
	align-self: flex-end;
	margin-left: 5px;
/*	border-radius: 3px;*/
}

.best-odds-explainer > div:last-child {
	max-width: 60px;
	width: 40%;
	height: 100%;
	background: #22d6ff;
}

.odds-compare-body-game {
	display: flex;
	background: #222222;
	margin-bottom: 5px;
}

.odds-compare-body-game-details {
	display: flex;
	width: 25%;
	min-width: 200px;
	border-right: 5px solid black;
}

.odds-compare-body-game-time {
	width: 25%;
	/*padding: 1.1em 0 0 0.8em;*/
	padding: 0 1.5em 0 1em;
	text-align: left;
	font-size: 0.8em;
	line-height: 55px;
}

.odds-compare-game-teams {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	text-align: left;
/*	padding: 0.5em;*/
}

.odds-compare-game-teams > div {
	/*line-height: 55px;*/
	/*padding: 0.2em;*/
}

.odds-compare-game-odds {
/*	display: flex;
    flex-direction: column;*/
	position: relative;
	width: 75%;
	height: 110px;
	/*height: 100%;*/
}

.odds-compare-game-odds-side {
	width: 100%;
	height: 50%;
	background: #272727;
	/*border-bottom: 1px solid #111111;*/
}

.odds-compare-game-odds-side.second {
	border-bottom: none;
	background: #222222;
}

.odds-compare-game-odds-side {
	display: flex;
}

.odds-compare-none {
	display: flex;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;
}

.odds-compare-none > div {
	align-self: center;
}

.odds-compare-item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 6vw;
	min-width: 90px;
	margin-right: 5px;
	align-self: center;
}

.odds-compare-item > div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
	width: 55px;
	height: 55px;
	/*background: black;
	background: #333333;*/
	border-radius: 5px;
}

.odds-compare-item.best-odds > div {
	background: #6ae000;
	background: #22d6ff;
	color: black;
}

.odds-compare-item > div > div {
	align-self: center;
	position: relative;
	bottom: 0.1em;
}

.odds-compare-item > div > div:last-child {
	font-size: 0.7em;
	margin-top: 0.1em;
}

.odds-compare-item > div > div:first-child {
	font-size: 1.1em;
}

.odds-compare-item > div > div.odds-compare-total {
	margin-top: 0.2em;
	font-size: 0.9em;
}

/*.odds-compare-item > div > div.odds-compare-moneyline {
	font-size: 0.9em;
}*/

.odds-compare-item span {
	font-size: 0.8em;
}

.odds-compare-moneyline span {
	margin-left: 0.2em;
}




.compare-page .titan-odds-logo {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	padding: 0.2em 0 0.5em 0.2em;
	margin-left: 0.5em;
	cursor: pointer;
}

.compare-page .titan-odds-logo img {
	height: 50px;
}


.compare-page-body {
	margin-top: 10vh;
}

.dashboard-outer {
	position: relative;
	width: 100%;	
	padding-bottom: 100px;
	font-family: Montserrat;
}

.dashboard {
	width: 360px;
	/*width: 340px;*/
	margin: 40px auto 0 auto;
	background: white;
	font-size: 15px;
	color: #333;
	box-shadow: 0 5px 8px rgba(0,0,0,0.10);
	border-radius: 5px;
}

.dashboard h2 {
	margin-bottom: 0px;
	padding: 10px;
	background: #627d91;
	background: #2d4d66;
	background: #515b66;
	background: #444951;
	color: white;
	/*color: #627d91;*/
	letter-spacing: 1px;
	/*border-bottom: 1px solid #eee;*/
	font-weight: lighter;
}

.dashboard-item.top {
	/*padding-bottom: 0;*/
}

.dashboard-menu {
	display: flex;
	justify-content: space-around;
	width: 80%;
	height: 35px;
	/*background: #9ca7af;
	background: #afb8bf;*/
	/*margin-bottom: 10px;*/
	margin: 30px auto 0 auto;
	margin-top: 30px;
	color: white;
	color: #515b66;
	border: 1px solid #515b66;
	border-radius: 3px;
	align-items: stretch;
}

.dashboard-menu-item {
	/*width: 33.33%;*/
	flex: 1 1;
	line-height: 35px;
	font-size: 11px;
	/*border-right: 1px solid #afb8bf;*/
	border-right: 1px solid #ad4045;
	background: #d85057;
	background: #c97922;
	color: white;
	cursor: pointer;
}

.dashboard-menu-item:last-child {
	border-right: none;
}

.dashboard-menu-item:hover {
	/*background: #edf0f2;
	background: #f2f3f4;*/
	-webkit-filter: brightness(90%);
	        filter: brightness(90%);
}

.dashboard-inner {
	display: flex;
	justify-content: space-around;
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
	padding: 20px 0;
}

.dashboard-item {
	padding: 15px 0;
}

.dashboard-inner .dashboard-item {
	width: 40%;
	cursor: pointer;
}

.dashboard-inner .dashboard-item:hover {
	background: rgba(0,0,0,0.03)
}

.dashboard-buttons {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 10px auto;
}

.dashboard .logout {
	display: inline-block;
	font-size: 13px;
	font-weight: bold;
	color: #627d91;
	cursor: pointer;
}

.dashboard-item button {
	align-self: center;
	width: 215px;
	height: 32px;
	margin-top: 6px;
	background: #d85057;
	background: #c61f36;
	background: #c97922;
	background: #6a707a;
	font-size: 12px;
	cursor: pointer;
}

.dashboard-item-body.api {
	font-size: 12px;
}

.dashboard-item-body span {
	font-weight: normal;
}

.dashboard-item-header {
	color: #89a1b2;
	color: #ffb300;
	color: #c97922;
	font-size: 18px;
	margin-bottom: 5px;
	font-weight: bold;
}

.dashboard .dashboard-item i {
	/*color: #627d91;*/
	/*color: #89a1b2;*/
	/*color: #b1c2ce;*/
	color: #c3d1db;
	font-size: 80px;
	line-height: normal;
	line-height: initial;
	margin-right: 0;
}

.dashboard .dashboard-inner .dashboard-item i {
	font-size: 70px;
}

.dashboard-item-body {
	margin-top: 10px;
	font-size: 14px;
	font-weight: bold;
	color: #333;
}

.dashboard-item-body.secondary {
	margin-top: 5px;
	font-size: 12px;
	font-weight: normal;
}

.dashboard-help {
	display: inline-block;
	display: hidden;
	padding: 20px;
	/*font-weight: bold;*/
	color: #d85057;
	color: #627d91;
	cursor: pointer;
}

.logged-out-dashboard {
	margin-top: 100px;
	color: #777;
}

.logged-out-dashboard > div {
	margin-bottom: 20px;
}

.logged-out-dashboard > button {
	width: 150px;
	height: 40px;
	background: #c61f36;
	cursor: pointer;
}



.footer {
	/*position: relative;
	top: -1px;*/
	width: 100vw;
	height: 100px;
	min-width: 800px;
	position: relative;
	z-index: 4;
	background: black;
/*	border: 1px solid black;	*/
}

.footer-inner {
	position: relative;
	max-width: 220px;
	margin: 0 auto 0 auto;
	padding: 0 2%;
	font-size: 16px;
}

.footer-link-list {
	display: flex;
	justify-content: space-around;
	list-style: none;
	position: relative;
	margin: 0;
    padding: 10px 0 0 0;
}

.footer-link {
	position: relative;
    padding-top: 5px;
    color: #bbb;
    cursor: pointer;
}

.pricing-footer .footer-link {
	color: white;
}

.footer-link:hover {
    color: #ddd;
}






.terms-page {
	position: relative;
	margin: 0 auto 0 auto;
	padding-bottom: 50px;
	text-align: left;
	font-size: 15px;
	font-weight: lighter;
	/*color: #333;
	color: #444951;*/
}

.terms-page .titan-odds-logo {
	display: flex;
	justify-content: flex-start;
	/*position: relative;
	right: 1em;*/
	width: 100%;
	padding: 0.2em 0 0.5em 0.2em;
	margin-left: 0.5em;
	cursor: pointer;
}

.terms-page .titan-odds-logo img {
	height: 50px;
}

.terms-page-body {
	padding: 1em;
}


.terms-page > div:nth-child(2) {
	padding-top: 5vh;
	max-width: 800px;
	margin: 0 auto 0 auto;
}

.terms-page > hr {
	border-top: 1px solid #eee;
}

.terms-disclaimer {
	/*width: calc(102% - 30px);
	position: relative;
	left: -1%;*/
	margin: 25px 0 20px 0;
	padding: 10px 15px;
	background: #e8eef7;
	font-size: 15px;
/*	color: #000;*/
	border-radius: 5px
}

.terms-disclaimer > .terms-subheader {
	padding-top: 5px;
}

.terms-header {
	margin-bottom: 28px;
	color: #ffb300;
	font-size: 28px;
}

.terms-type-header {
	margin-bottom: 28px;
	color: #f46065;
	font-size: 18px;
}

.terms-subheader {
	padding: 20px 0 0 0;
	font-size: 20px;
	font-weight: normal;
/*	color: #000;
	color: #444951;*/
}

.terms-page p {
	line-height: 22px;
}

.terms-link {
	font-weight: bold;
	font-weight: normal;
	color: #960939;
	color: #0099e5;
	color: #22cca7;
	text-decoration: underline;
	cursor: pointer;
}





.contact-page {
	position: absolute;
	top: 0;
	width: 100vw;
	height: 100vh;
	background: black;
	color: white;
}

.contact-page-go-home {
	position: absolute;
	top: 0.5em;
	right: 0.5em;
	font-size: 1.2em;
	font-family: "Rubik";
/*	font-weight: 600;*/
	color: #22d6ff;
	color: #ffb300;
	cursor: pointer;
}

.contact-page .titan-odds-logo {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	padding: 0.2em 0 0.5em 0.2em;
	margin-left: 0.5em;
	cursor: pointer;
}

.contact-page .titan-odds-logo img {
	height: 50px;
}

.contact-page .contact-form-header {
	color: white;
	margin-bottom: 2vh;
	margin-top: 12vh;
}

.contact-page p {
	margin: 1.5em auto;
	max-width: 800px;
	text-align: center;
}

._404 {
	height: 100vh;
	padding-top: 20vh;
	color: white;
}

._404-header {
	font-size: 100px;
	font-family: "Rubik";
}

._404-body {
	font-size: 20px;
}

._404-button {
	margin-top: 20px;
	background: transparent;
	width: 180px;
	height: 50px;
	color: #22d6ff;
	color: white;
	border: 1px solid #22d6ff;
	border-radius: 0;
	box-shadow: none;
	font-size: 18px;
	font-weight: 700;
}
/*
._404-button:hover {
	background: #22d6ff;
	color: white;
}*/

#react-container {
  width: 100%;
}

.page-holder {
  position: relative;
  width: 100%;
  min-height: 100vh;
  /*padding-top: 65px;
  padding-top: 120px;*/
/*  min-height: calc(100vh - 165px);*/
  z-index: 2;
  background: black;
  color: white;
}

#react-container.dash {
  background: #f3f3f3;
}

#react-container.pric {
	background: #a64643;
	background-image: linear-gradient(to top right, #301023, #6a273a, #a64643, #d87140, #f9a836);
  background-image: linear-gradient(to right top, #3e4449, #5c6369, #7b838a, #9ca5ad, #bec8d1);
  background-image: linear-gradient(to right top, #313e47, #4a5862, #64737e, #7f8f9c, #9cacba);
  background: #2f3338;
  background: #535c68;
  /*background-image: linear-gradient(to right top, #2f3438, #41474c, #545a60, #686f76, #7c848c);*/
 /* background-image: url("/images/home-banner-muted.png");
  background-size: cover;
  background-position: 50% 65%;*/

 /* background-image: url(/static/media/home-banner-muted.4bfdb257.png);
  background-size: cover;
  background-position: 50% 65%;
  opacity: 1;
  -webkit-filter: grayscale(50%);
  filter: grayscale(50%);*/
  
}






