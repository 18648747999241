
.footer {
	/*position: relative;
	top: -1px;*/
	width: 100vw;
	height: 100px;
	min-width: 800px;
	position: relative;
	z-index: 4;
	background: black;
/*	border: 1px solid black;	*/
}

.footer-inner {
	position: relative;
	max-width: 220px;
	margin: 0 auto 0 auto;
	padding: 0 2%;
	font-size: 16px;
}

.footer-link-list {
	display: flex;
	justify-content: space-around;
	list-style: none;
	position: relative;
	margin: 0;
    padding: 10px 0 0 0;
}

.footer-link {
	position: relative;
    padding-top: 5px;
    color: #bbb;
    cursor: pointer;
}

.pricing-footer .footer-link {
	color: white;
}

.footer-link:hover {
    color: #ddd;
}




