
.contact-page {
	position: absolute;
	top: 0;
	width: 100vw;
	height: 100vh;
	background: black;
	color: white;
}

.contact-page-go-home {
	position: absolute;
	top: 0.5em;
	right: 0.5em;
	font-size: 1.2em;
	font-family: "Rubik";
/*	font-weight: 600;*/
	color: #22d6ff;
	color: #ffb300;
	cursor: pointer;
}

.contact-page .titan-odds-logo {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	padding: 0.2em 0 0.5em 0.2em;
	margin-left: 0.5em;
	cursor: pointer;
}

.contact-page .titan-odds-logo img {
	height: 50px;
}

.contact-page .contact-form-header {
	color: white;
	margin-bottom: 2vh;
	margin-top: 12vh;
}

.contact-page p {
	margin: 1.5em auto;
	max-width: 800px;
	text-align: center;
}